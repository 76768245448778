import React from 'react';
import FrontFooter from './common/Front-Footer';
import FrontWrapper from './common/FrontWrapper';

class Presence extends React.Component {
    render() {
        return (
            <FrontWrapper location={this.props.location}>
                <div className="scroll-content-wrapper" data-scrollbar>
                    <div className="page-main page-current">
                        <div className="page-toload legacy-page" data-bodyclassname="legacy">
                            <main className="page-content" role="main">
                                <div className="our-presence-wrap">
                                    <div className="container-fluid">
                                        <section style={{ marginTop: '0px' }} className="section-container our-story our-service-bn contact-banner">
                                            <div className="mb-20">
                                                <h2 className="show-text head padd-max js-type-animation-alt">
                                                    Our Presence
                                                </h2>
                                            </div>
                                        </section>
                                        <div className="presence-section">
                                            <div className="map-wrap">
                                                <div className="presence-points">
                                                    <div className="canada presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/canada.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Canada</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="usa presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/united-states.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>USA</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="mexico presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/mexico.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Mexico</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="st-martin presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/st-martin.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>St. Martin</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="peru presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/peru.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Peru</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="iceland presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/iceland.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Iceland</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="finland presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/finland.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Finland</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="sweden presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/sweden.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Sweden</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="norway presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/norway.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Norway</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="nor-ireland presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/northern-ireland.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Northern Ireland</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="ireland presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/ireland.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Ireland</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="uk presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/uk.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>U.K.</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="belgium presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/belgium.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Belgium</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="russia presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/russia.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Russia</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="slovakia presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/slovakia.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Slovakia</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="bratislava presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/bratislava.png')}
                                                                            alt=""
                                                                        />
                                                                        <p>Bratislava</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="poland presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/poland.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Poland</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="latvia presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/latvia.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Latvia</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="denmark presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/denmark.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Denmark</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="france presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/france.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>France</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="spain presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/spain.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Spain</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="gibraltar presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/gibraltar.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Gibraltar</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="lithuania presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/lithuania.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Lithuania</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="germany presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/germany.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Germany</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="monaco presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/monaco.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Monaco</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="switzerland presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/switzerland.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Switzerland</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="italy presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/italy.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Italy</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="czr presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/cz.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Check Republic</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="romania presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/romania.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Romania</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="austria presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/australia.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Austria</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="hungary presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/hungary.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Hungary</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="turkey presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/turkey.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Turkey</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="greece presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/greece.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Greece</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="cyprus presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/northern-cyprus.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Cyprus</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="israel presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/israel.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Israel</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="lebanon presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/lebanon.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Lebanon</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="malta presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/malta.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Malta</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="armenia presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/armenia.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Armenia</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="bahrain presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/bahrain.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Bahrain</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="uae presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/united-arab-emirates.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>U.A.E.</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="zambia presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/zambia.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Zambia</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="botswana presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/botswana.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Botswana</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="south-africa presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/south-africa.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>South Africa</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="mauritius presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/mauritius.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Mauritius</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="india presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/india.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>India</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="srilanka presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/sri-lanka.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Sri Lanka</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="maldives presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/maldives.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Maldives</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="thailand presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/thailand.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Thailand</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="china presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/china.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>China</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="hk presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/hong-kong.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Hong Kong</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="taiwan presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/taiwan.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Taiwan</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="south-korea presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/south-korea.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>South Korea</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="japan presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/japan.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Japan</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="malaysia presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/malaysia.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Malaysia</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="singapore presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/singapore.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Singapore</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="australia presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/je.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/australia.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>Australia</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="nz presence">
                                                        <div className="popup">
                                                            <img
                                                                src={require('../../../static/assets/img/presenceImg/di.png')}
                                                                alt=""
                                                            />
                                                            <span className="popup-content">
                                                                <span className="popup-text">
                                                                    <span className="popup-inner">
                                                                        <img
                                                                            src={require('../../../static/assets/img/presenceImg/flags/new-zealand.svg')}
                                                                            alt=""
                                                                        />
                                                                        <p>New Zealand</p>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <FrontFooter />
                            </main>
                            <div className="preload" aria-hidden="true" />
                        </div>
                    </div>
                    <div className="page-main page-next" aria-hidden="true" />
                    <div className="page-main page-prev" aria-hidden="true" />
                    <footer className="footer" role="contentinfo" />
                </div>
            </FrontWrapper>
        );
    }
}

export default Presence;
