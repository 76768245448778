import React from "react";
import Presence from "../components/FrontStatic/Presence";
import SEO from "../components/SEO";
import {graphql} from "gatsby";

const PresenceComponent = (props) => <React.Fragment>
    <SEO {...(props?.data?.seo?.frontmatter || {})}/>
    <Presence {...props} />
</React.Fragment>

export default PresenceComponent

export const query = graphql`
  query PresenceQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "presence-seo"}}) {
        frontmatter {
            title
            description
        }
    }
 }
`;